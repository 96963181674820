<template>
  <div class="manage-subscription">
    <c-heading
      :fontSize="{ base: 'sm', lg: '2xl', xl: '4xl' }"
      as="h1"
      display="flex"
      alignItems="baseline"
      mb="4"
    >
      <c-text mr="3"> Change Plan </c-text>
    </c-heading>

    <c-box mb="8" d="flex" justify-content="space-between">
      <c-box d="flex" align-items="center">
        <c-text fontSize=".8em">Your current plan is</c-text>
        <div>
          <c-text
            rounded="md"
            ml="2"
            px="3"
            py="1"
            bg="#ef5323"
            color="white"
            fontSize=".6em"
            >{{ getCurrentPlan.name }}
          </c-text>
        </div>
      </c-box>

      <c-button
        @click="$router.push({ name: 'Subscription' })"
        size="md"
        px="8"
        variant-color="vc-orange"
        variant="outline"
        class="hover_btn"
      >
        Plans
      </c-button>
    </c-box>
    <div class="d-grid">
      <div class="plan">
        <c-pseudo-box
          v-for="(plan, ix) in plans"
          :key="ix"
          px="4"
          py="3"
          mb="3"
          w="100%"
          box-shadow="md"
          rounded="md"
          @click="selectedPlan = plan"
          v-chakra
          :class="
            selectedPlan.title === plan.title ? 'plan_card_active' : 'plan_card'
          "
          :_hover="{
            border: '1px',
            borderColor: '#ef5323',
            cursor: 'pointer',
          }"
        >
          <c-text
            d="flex"
            align-items="center"
            color="#ef5323"
            fontSize="xs"
            mb="2"
            >{{ plan.title }}
          </c-text>

          <c-text fontWeight="bold" mb="3" fontSize="md"
            >{{ plan.description }} b</c-text
          >

          <c-flex justify-content="space-between">
            <c-text fontSize="xs" mb="4">{{ plan.stage }}</c-text>

            <svg
              v-if="selectedPlan.title === plan.title"
              v-chakra="{
                width: '18px',
                height: '18px',
                ml: '2',
                color: 'vc-orange.300',
              }"
            >
              <use href="@/assets/icons/check-circle.svg#check"></use>
            </svg>
          </c-flex>

          <c-text fontWeight="bold" fontSize="md"
            >${{ plan.monthlyPrice.price }}</c-text
          >
          <c-text fontSize="xs" mb="1">{{ plan.frequency }}</c-text>
        </c-pseudo-box>
      </div>
      <div class="plan_details">
        <c-grid-item>
          <c-box mb="4">
            <c-text px="2" py="3" mb="1" fontSize="md" fontWeight="bold">
              Payment Information</c-text
            >
            <div style="text-align: center" v-show="isLoadingCards">
              <c-spinner color="#EF5323" />
            </div>

            <c-grid
              w="100%"
              px="2"
              template-columns="repeat(12, 1fr)"
              gap="4"
              v-show="!isLoadingCards"
            >
              <c-grid-item col-span="8">
                <c-box bg="white" mb="3" p="3" box-shadow="md" rounded="md">
                  <div id="cc" ref="cad"></div>
                  <br />
                  <c-box v-if="!noActiveCard" px="3">
                    <div>
                      <c-button
                        @click="saveCardDetails"
                        size="md"
                        px="8"
                        variant-color="vc-orange"
                        variant="outline"
                        class="hover_btn"
                      >
                        Save New Card
                        <c-text ml="1" fontSize="1.5em" color="#ef5323"
                          >+</c-text
                        >
                      </c-button>
                    </div>
                  </c-box>
                  <div v-if="isNoCustomer">
                    <c-box px="3" py="1">
                      <label for>Card Holder Name</label>
                      <c-input
                        v-model="form.name"
                        placeholder="Enter Full Name"
                        type="text"
                      />
                    </c-box>

                    <c-box px="3" py="1">
                      <label for>Email Address</label>
                      <c-input
                        v-model="form.email"
                        placeholder="Enter Email Address"
                        type="email"
                      />
                    </c-box>

                    <c-box px="3" py="1">
                      <label for>Phone Number</label>
                      <c-input
                        v-model="form.phone"
                        placeholder="Enter Phone Number"
                        type="number"
                      />
                    </c-box>

                    <c-box px="3">
                      <c-button
                        @click="saveCustomer"
                        size="md"
                        px="8"
                        mt="5"
                        :isLoading="saveBtnLoading"
                        variant-color="vc-orange"
                        :disable="noActiveCard"
                      >
                        Save
                      </c-button>
                    </c-box>
                  </div>
                </c-box>
              </c-grid-item>

              <c-grid-item col-span="8">
                <c-box mb="3" box-shadow="md" rounded="md">
                  <c-text px="4" py="3" fontSize="md" fontWeight="bold">
                    Billing Information</c-text
                  >

                  <c-box
                    mx="4"
                    mb="4"
                    mt="2"
                    border="1px"
                    borderColor="gray.200"
                  >
                    <c-box px="5" py="3" v-if="!noActiveCard">
                      <c-text color="#ef5323" fontSize="xs" mb="2"
                        >{{ activeSub.name }}
                      </c-text>
                      <c-text mb="3" fontSize="md" fontWeight="bold">{{
                        activeSub.description
                      }}</c-text>

                      <c-text
                        mb="3"
                        fontSize=".8em"
                        color="gray.500"
                        v-if="activeSub.name !== 'Free'"
                      >
                        Next payment is due on
                        {{ activeSub.nextBillingDate }} ${{ activeSub.price }}
                        USD
                        {{ activeSub.frequency }}
                      </c-text>

                      <c-box>
                        <c-text mb="3" fontSize="sm" fontWeight="semibold"
                          >Payment card</c-text
                        >
                        <c-box
                          display="flex"
                          rounded="lg"
                          align-items="center"
                          my="4"
                          w="50%"
                          bg="#F4F6FC"
                        >
                          <c-box rounded="md" mr="2" py="2" pl="2" w="3em">
                            <img
                              v-if="activeCard.brand === 'visa'"
                              class="pl"
                              v-chakra
                              w="90%"
                              src="@/assets/img/visa-img.png"
                              alt="Card Type - Visa"
                            />
                            <img
                              v-else
                              class=""
                              v-chakra
                              w="90%"
                              src="@/assets/img/mastercard-img.png"
                              alt="Card Type - Mastercard"
                            />
                          </c-box>
                          <c-text
                            diplay="flex"
                            align-items="center"
                            justify="center"
                            color="gray.500"
                          >
                            **** {{ activeCard.last4 }}
                          </c-text>
                        </c-box>
                      </c-box>

                      <c-box display="flex">
                        <div v-if="activeSub.name !== 'Free'">
                          <c-button
                            @click="onEndPlanClick"
                            size="md"
                            px="5"
                            w="100%"
                            variant-color="vc-orange"
                          >
                            End plan
                          </c-button>
                        </div>

                        <c-button
                          @click="showCards = !showCards"
                          size="md"
                          px="5"
                          ml="3"
                          variant-color="vc-orange"
                          variant="outline"
                          class="hover_btn"
                        >
                          Change card
                          <svg v-chakra fill="#ef5323" w="6" h="6" ml="1">
                            <use
                              href="@/assets/icons/icon-refresh.svg#refresh"
                            ></use>
                          </svg>
                        </c-button>
                      </c-box>
                    </c-box>

                    <span v-else>You have no Plan or Bills</span>

                    <c-box bg="#F4F6FC" mt="3" px="5" py="3" v-if="showCards">
                      <c-grid mb="3" template-columns="repeat(5, 1fr)" gap="6">
                        <c-grid-item col-span="3">
                          <c-text mb="3" fontSize="md" fontWeight="semibold"
                            >Recent Cards</c-text
                          >

                          <c-box
                            w="100%"
                            bg="white"
                            d="flex"
                            align-items="center"
                            justify-content="space-between"
                            h="3em"
                            py="2"
                            px="3"
                            mb="3"
                            rounded="md"
                            v-for="(card, index) in cards"
                            :key="index"
                          >
                            <c-box d="flex" align-items="center">
                              <div v-if="card.IsActive" d="flex">
                                <div
                                  class="active"
                                  @click="defaultCard(card)"
                                ></div>
                              </div>
                              <c-text v-else>
                                <div
                                  class="not_active"
                                  @click="defaultCard(card)"
                                ></div>
                              </c-text>

                              <c-box
                                backgroundSize="cover"
                                bgRepeat="no-repeat"
                                backgroundPosition="center center"
                                rounded="md"
                                mr="2"
                                w="3em"
                              >
                                <img
                                  v-if="card.brand === 'visa'"
                                  class=""
                                  v-chakra
                                  w="90%"
                                  src="@/assets/img/visa-img.png"
                                  alt="Card Type - Visa"
                                />
                                <img
                                  v-else
                                  class=""
                                  v-chakra
                                  w="90%"
                                  src="@/assets/img/mastercard-img.png"
                                  alt="Card Type - Mastercard"
                                />
                              </c-box>

                              <c-text>**** {{ card.last4 }}</c-text>
                            </c-box>

                            <svg
                              @click="deleteCard(card)"
                              fill="vc-orange.300"
                              v-chakra="{
                                cursor: 'pointer',
                                width: '12px',
                                height: '12px',
                              }"
                            >
                              <use
                                href="@/assets/icons/minus-box-fill.svg#minus"
                              ></use>
                            </svg>
                          </c-box>

                          <c-text
                            font-size=".7em"
                            v-if="cards.length === 0 && !isLoadingCards"
                            >No cards added yet</c-text
                          >
                          <ContentLoader
                            v-if="isLoadingCards"
                            viewBox="0 0 250 130"
                            primaryColor="#ddd"
                          >
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="150"
                              height="10"
                            />
                          </ContentLoader>
                        </c-grid-item>
                      </c-grid>
                    </c-box>
                  </c-box>
                </c-box>
                <c-box bg="white" mb="3" box-shadow="md" rounded="md">
                  <c-box d="flex" justify-content="space-between">
                    <div>
                      <c-text
                        px="4"
                        py="3"
                        mb="1"
                        fontSize="md"
                        fontWeight="bold"
                        color="vc-orange.300"
                      >
                        Subscribe Plan</c-text
                      >
                      <div class="flex" style="align-items: center">
                        <c-text
                          px="2"
                          mb="1"
                          fontSize="3xl"
                          color="black"
                          fontWeight="bold"
                        >
                          ${{
                            selectedPlan.isMonthly
                              ? selectedPlan.monthlyPrice.price
                              : selectedPlan.yearlyPrice.price
                          }}
                        </c-text>

                        <c-text fontSize="md" color="grey" fontWeight="bold">
                          {{
                            selectedPlan.isMonthly ? 'per month' : 'per year'
                          }}
                        </c-text>
                      </div>
                    </div>

                    <c-box d="flex" align-items="center">
                      <c-text
                        mr="2"
                        fontSize="xs"
                        d="flex"
                        color="black"
                        fontWeight="bold"
                        >Bill Cycle
                      </c-text>
                      <label
                        :for="`toggle-billing-checked-${selectedPlan.title}`"
                        class="toggle-button"
                      >
                        <input
                          type="checkbox"
                          :id="`toggle-billing-checked-${selectedPlan.title}`"
                          :checked="!selectedPlan.isMonthly"
                          @change="
                            selectedPlan.isMonthly = !selectedPlan.isMonthly
                          "
                        />
                        <div class="toggle-bg"></div>
                        <c-text
                          mx="2"
                          fontSize="xs"
                          d="flex"
                          color="#ef5323"
                          fontWeight="bold"
                          >Yearly
                        </c-text>
                      </label>
                    </c-box>
                  </c-box>
                  <c-box px="4" py="3" borderTop="1px" borderColor="gray.200">
                    <c-grid w="100%" template-columns="repeat(2, 1fr)" gap="1">
                      <c-box fontSize="sm">
                        <c-text color="black" fontWeight="bold"
                          >1x {{ selectedPlan.title }}
                          {{ selectedPlan.isMonthly ? 'monthly' : 'annual' }}
                          plan</c-text
                        >
                      </c-box>

                      <c-box textAlign="right" h="10">
                        <c-text fontSize="md" color="black" fontWeight="500"
                          >${{
                            selectedPlan.isMonthly
                              ? selectedPlan.monthlyPrice.price
                              : selectedPlan.yearlyPrice.price
                          }}</c-text
                        >
                      </c-box>
                    </c-grid>

                    <c-grid
                      w="100%"
                      template-columns="repeat(2, 1fr)"
                      gap="1"
                      borderTop="1px"
                      borderColor="gray.200"
                    >
                      <c-box fontSize="sm" mt="4">
                        <c-text color="black" fontWeight="bold"
                          >Sales tax</c-text
                        >
                      </c-box>
                      <c-box textAlign="right" h="10" mt="4">
                        <c-text fontSize="sm" color="gray.400">$0.00</c-text>
                      </c-box>
                    </c-grid>

                    <c-grid
                      w="100%"
                      template-columns="repeat(2, 1fr)"
                      gap="1"
                      borderTop="1px"
                      borderColor="gray.200"
                    >
                      <c-box fontSize="sm" mt="4">
                        <c-text color="black" fontWeight="bold">Coupon</c-text>
                      </c-box>
                      <c-box textAlign="right" h="10" mt="4">
                        <c-input
                          v-model="coupon"
                          placeholder="Enter Coupon"
                          type="text"
                        />
                      </c-box>
                    </c-grid>
                  </c-box>
                  <c-box px="4" py="3" borderTop="1px" borderColor="gray.200">
                    <c-grid w="100%" template-columns="repeat(2, 1fr)" gap="6">
                      <c-box fontSize="sm">
                        <c-text color="black" fontWeight="bold">Total</c-text>
                      </c-box>

                      <c-box textAlign="right" h="10">
                        <c-text fontSize="md" color="black" fontWeight="500"
                          >${{ totalCost }}</c-text
                        >
                      </c-box>
                    </c-grid>
                  </c-box>
                  <c-box px="4" py="3">
                    <c-box mb="3">
                      <label style="font-size: 16px">
                        <input type="checkbox" v-model="agreeToPlan" /> I agree
                        to the Terms of Service and acknowledge the Privacy
                        Policy
                      </label>

                      <!-- <checkbox v-model="agreeToPlan">
                        i agree to the
                      </checkbox>
                      <c-checkbox v-model="agreeToPlan" fontSize="xs"
                        >I agree to the Terms of Service and acknowledge the
                        Privacy Policy</c-checkbox
                      > -->
                    </c-box>
                    <c-button
                      :disabled="!agreeToPlan"
                      @click="subscribeToPlan"
                      size="md"
                      px="8"
                      w="100%"
                      variant-color="vc-orange"
                      :isLoading="btnLoading"
                    >
                      Subscribe to {{ selectedPlan.title }}
                    </c-button>
                  </c-box>
                </c-box>

                <c-box mb="3" box-shadow="md" rounded="md">
                  <c-button
                    @click="showBillingHistory = true"
                    size="md"
                    px="8"
                    w="100%"
                    variant-color="vc-orange"
                  >
                    Show Billing history
                  </c-button>
                </c-box>
              </c-grid-item>
            </c-grid>
          </c-box>
        </c-grid-item>
      </div>
    </div>

    <c-modal :is-open="showSubscribeModal" :closeOnOverlayClick="false">
      <c-modal-content ref="content">
        <c-modal-header>Thank you for subscribing</c-modal-header>
        <c-modal-body>
          <c-box display="flex" align-items="center" justify-content="center">
            <p>
              You have successfully subscribed to our
              {{ selectedPlan.title }} plan. Get started now to enjoy our
              premium services.
            </p>
          </c-box>
        </c-modal-body>
        <c-modal-footer>
          <c-button variant-color="vc-orange" mr="3" @click="gotoApp">
            Start {{ selectedPlan.title }} Plan
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>

    <c-modal :is-open="showBillingHistory" :closeOnOverlayClick="true">
      <c-modal-content ref="content">
        <c-modal-header>Billing History</c-modal-header>
        <c-modal-body>
          <BillingHistory />
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BillingHistory from './BillingHistory.vue';
// import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import {
  addCard,
  getCards,
  deleteCard,
  defaultCard,
  createCustomer,
  createSubscription,
  cancelSubscription,
  getClientSecret,
} from '../../../services/subscription';
import { ContentLoader } from 'vue-content-loader';
import { loadStripe } from '@stripe/stripe-js';
import subscribtionMixin from '@/mixins/subscribtion.js';

export default {
  mixins: [subscribtionMixin],
  components: {
    ContentLoader,
    BillingHistory,
  },
  data() {
    return {
      showBillingHistory: false,
      btnLoading: false,
      showSubscribeModal: false,
      history: [],
      selectedHokage: '2',
      token: null,
      card: '',
      agreeToPlan: false,
      isAddingCard: false,
      hasASubscription: false,
      publishableKey:
        'pk_test_51L5BIlDpNBuvEcjKIvja9t2j6abTRMZpArz6B11rXmninREOAZG6NoOzG7I10Q9UHKiKbTDYURY6zvLSZAPaEV6Q00TBF5qAQ3',
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret: '',
      },
      addedCustomer: false,
      saveBtnLoading: false,
      showCards: false,
      isEndPlanOpen: false,
      isLoadingCards: true,
      selectedPlan: {},
      activeSub: {},
      coupon: '',
      form: {
        name: '',
        phone: '',
        email: '',
      },
      stripe: '',
      plans: [
        {
          title: 'Pro',
          description: 'From Validation to Commercialization',
          stage:
            'Stage: Validation (Market research and product-market fit), Operational',
          price: '$12',
          frequency: 'per month',
          isMonthly: true,
          monthlyPrice: {},
          yearlyPrice: {},
        },
        {
          title: 'Premium',
          description: 'From Operational to Scaling',
          stage: 'Stage: Scaling, fully established',
          price: '$30',
          frequency: 'per month',
          isMonthly: true,
          monthlyPrice: {},
          yearlyPrice: {},
        },
      ],
      cards: [],
      activeCard: [],
    };
  },
  mounted() {
    this.generatePaymentIntent();
    this.getPlans();
    this.example();
    this.getCards();
    this.activeSub = this.getActiveSubscription();
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('subscription', {
      storePlans: (state) => state.plans,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    totalCost() {
      return this.selectedPlan.isMonthly
        ? this.selectedPlan.monthlyPrice.price
        : this.selectedPlan.yearlyPrice.price;
    },
    noActiveCard() {
      return this.cards.length === 0;
    },
    isNoCustomer() {
      return (
        this.activeCompany.stripeId === null ||
        this.activeCompany.stripeId === ''
      );
    },
  },
  methods: {
    async generatePaymentIntent() {
      const cs = await getClientSecret();
      this.elementsOptions.clientSecret = cs.data.data.clientSecret;

      this.example();
    },
    async example() {
      const pubKey =
        process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY ||
        'pk_test_51L5BIlDpNBuvEcjKIvja9t2j6abTRMZpArz6B11rXmninREOAZG6NoOzG7I10Q9UHKiKbTDYURY6zvLSZAPaEV6Q00TBF5qAQ3';
      let strip = await loadStripe(pubKey);
      this.stripe = strip;

      let elements = strip.elements({
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600',
          },
        ],
      });

      this.card = elements.create('card', {
        hidePostalCode: true,
        style: {
          base: {
            lineHeight: '40px',
            fontSize: '1.1rem',
            fontFamily: '"Montserrat",sans-serif',
            color: '#1A202C',

            '::placeholder': {
              color: '#A0AEC0',
            },
          },
        },
      });

      this.card.mount(this.$refs.cad);
    },
    getCards() {
      this.isLoadingCards = true;
      getCards()
        .then((res) => {
          this.cards = res.data.data;

          this.activeCard = this.cards.find((card) => card.IsActive);
        })

        .finally(() => {
          this.isLoadingCards = false;
        });

      this.isLoadingCards = false;
    },
    saveCardDetails() {
      this.stripe
        .confirmCardSetup(this.elementsOptions.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              email: this.form.email ? this.form.email : this.user.email,
            },
          },
        })

        .then((res) => {
          // this.btnLoading = false;
          console.log(res);
          if (res.error) {
            this.$toast({
              title: 'An error occurred.',
              description: `Error adding billing card. ${res.error.message}`,
              status: 'error',
              position: 'top',
              duration: 6000,
            });
          } else {
            this.getCards();
            this.$toast({
              title: 'Success.',
              description: `New billing card added successfully.`,
              status: 'success',
              position: 'top',
              duration: 6000,
            });
          }
        })
        .catch((err) => {
          // this.btnLoading = false;
          console.log(err);
          this.$toast({
            title: 'An error occurred..',
            description: `Error trying to bill card.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });

      // this.btnLoading = false;
    },
    onEndPlanClick() {
      this.isEndPlanOpen = !this.isEndPlanOpen;
      cancelSubscription();
      this.$toast({
        title: 'Success!!!',
        description: `Subscription renewal has been cancelled successfully`,
        status: 'success',
        position: 'top',
        duration: 3000,
      });
    },
    handleAddCard() {
      this.isAddingCard = true;
      this.$refs.elementRef.submit();
      // createCustomer({
      //   email: this.user.email,
      //   name: `${this.user.firstname} ${this.user.lastname}`,
      // }).then((res) => {
      //   console.log(res);
      // });
    },
    async subscribeToPlan() {
      this.btnLoading = true;

      await this.saveCardDetails();

      await new Promise((resolve) => setTimeout(resolve, 5000));

      this.createPlanSubscription();

      // if (this.addedCustomer || !this.noActiveCard) {
      //   this.createPlanSubscription();
      // }

      // if (!this.addedCustomer && this.noActiveCard) {
      //   await this.saveCustomer();

      //   // wait for 5 seconds
      //   await new Promise((resolve) => setTimeout(resolve, 4000));

      //   await this.createPlanSubscription();
      // }
    },
    createPlanSubscription() {
      createSubscription({
        // coupon: this.form.coupon,
        priceId: this.selectedPlan.isMonthly
          ? this.selectedPlan.monthlyPrice.stripeId
          : this.selectedPlan.yearlyPrice.stripeId,
        // parentCompanyId: this.activeCompany.id,
        invoice_timestamp: '1674846890',
        coupon: this.form.coupon,
      })
        .then((res) => {
          console.log(res);
          this.$toast({
            title: 'Success',
            description: `Subscription created successfully`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          this.btnLoading = false;
          this.showSubscribeModal = true;
        })
        .catch((error) => {
          const err = Object.assign({}, error);
          const { message } = err.response.data;

          this.btnLoading = false;
          this.$toast({
            title: 'An error occurred.',
            description: `${message}`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    saveCustomer() {
      this.saveBtnLoading = true;
      createCustomer({
        email: this.form.email,
        name: this.form.name,
        phone: this.form.phone,
      })
        .then(async () => {
          this.addedCustomer = true;
          // this.$refs.elementRef.submit();
          await this.generatePaymentIntent();
          await new Promise((resolve) => setTimeout(resolve, 3000));

          await this.saveCardDetails();
          this.saveBtnLoading = false;
          this.$toast({
            title: 'Saved.',
            description: `Customer account created successfully.`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        })
        .catch(async (error) => {
          this.saveBtnLoading = false;
          console.log(error);

          this.$toast({
            title: 'An error occurred.',
            description: `${error.response.data.message}`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    deleteCard(card) {
      const cardIndex = this.cards.indexOf(card);
      deleteCard({ cardPaymentId: card.cardId })
        .then(() => {
          this.$toast({
            title: 'Success!!!',
            description: `Card has been deleted`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          this.cards.splice(cardIndex, 1);
        })
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Can not remove default card.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    defaultCard(card) {
      // const cardIndex = this.cards.indexOf(card);
      // this.cards.splice(cardIndex, 1);
      this.isLoadingCards = true;
      defaultCard({ cardPaymentId: card.cardId })
        .then((res) => {
          this.$toast({
            title: 'Success!!!',
            description: `Billing card changed successfully`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          console.log(res.data);
          this.getCards();
        })
        .catch(() => {
          // this.cards.splice(cardIndex, 0, card);
          this.$toast({
            title: 'An error occurred.',
            description: `Please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    addCard(token) {
      const set = {
        expiredDate: `${token.card.exp_year}-${token.card.exp_month}-01`,
        name: token.card.name || '',
        last4: token.card.last4,
        bin: token.card.bin || '',
        token: token.id,
        userId: this.user.id,
      };

      addCard(set)
        .then(() => {
          this.$toast({
            title: 'Success!!!',
            description: `Card has been added`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast({
            title: 'An error occurred.',
            description: `Could not add card, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
          // this.isAddingCard = false;
        })
        .finally(() => {
          this.isAddingCard = false;
        });
    },
    tokenCreated(token) {
      this.isAddingCard = true;
      this.addCard(token);
      console.log(token);
    },
    getPlans() {
      // console.log('right here', this.storePlans);
      const selectedPlan = this.$route.query.plan;
      const selectedFrequency = this.$route.query.frequency;
      if (selectedPlan) {
        this.selectedPlan = this.plans.find(
          (plan) => plan.title === selectedPlan
        );

        if (selectedFrequency !== 'MONTHLY')
          this.selectedPlan.isMonthly = false;
      }
      // const freePlan = this.storePlans.find((_item) => _item.name === 'Free');
      const premiumPlan = this.storePlans.find(
        (_item) => _item.name === 'Premium'
      );
      const proPlan = this.storePlans.find((_item) => _item.name === 'Pro');

      this.plans[0].monthlyPrice = proPlan?.plan_prices?.find(
        (price) => price.frequency === 'MONTHLY'
      );
      this.plans[0].yearlyPrice = proPlan?.plan_prices?.find(
        (price) => price.frequency === 'YEARLY'
      );

      this.plans[1].monthlyPrice = premiumPlan?.plan_prices?.find(
        (price) => price.frequency === 'MONTHLY'
      );
      this.plans[1].yearlyPrice = premiumPlan?.plan_prices?.find(
        (price) => price.frequency === 'YEARLY'
      );
    },
    gotoApp() {
      this.$router.push({
        path: `/app/`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-subcription {
  min-height: 100vh;
  overflow-y: scroll;
}
.plan_card {
  background-color: white;
}
.plan_card_active {
  border: 1px solid #ef5323;
  background-color: #f4f6fc;
}
.toggle-button {
  @apply flex items-center cursor-pointer relative;

  .toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-4 w-5 transition shadow-sm;
  }

  input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
  }

  input:checked + .toggle-bg {
    @apply bg-secondary border-secondary;
  }

  input {
    @apply sr-only;
  }

  div {
    @apply bg-gray-200 border-2 border-gray-200 h-4 w-10 rounded-full;
  }
}

label:not(.toggle-button) {
  font-size: 0.8em;
}
.d-grid {
  @apply grid grid-cols-12 gap-3;
}
.plan {
  @apply col-span-4;
}
.plan_details {
  @apply col-span-8;
  padding: 0px 30px;
  // height: 100vh !important;
  // overflow-y: scroll;
}
.not_active {
  @apply h-4 w-4 mx-2 rounded-full border border-secondary cursor-pointer hover:shadow-xl;
}
.active {
  @apply h-4 w-4 mx-2 rounded-full border cursor-pointer  hover:shadow-xl;
  background-color: green;
  border-color: green;
}
#cc {
  @apply border border-solid bg-white px-4 rounded-md;
}

.css-kfzfjk {
  border-radius: 10px;
}
</style>
