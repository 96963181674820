<template>
  <div>
    <div style="text-align: center" v-if="isLoadingHistory">
      <c-spinner color="#EF5323" />
    </div>
    <div v-else>
      <c-box
        px="4"
        py="3"
        borderTop="1px"
        borderColor="gray.200"
        v-for="bill in history"
        :key="bill.id"
      >
        <c-grid w="100%" template-columns="repeat(3, 1fr)" gap="6">
          <c-box h="10" fontSize="sm">{{ formatDate(bill.dateCharged) }}</c-box>
          <c-box h="10">
            <c-text fontSize="sm">{{ bill.plan }}</c-text>
            <c-text color="blue.400" fontSize="xs">{{
              bill.actualDuration
            }}</c-text>
          </c-box>
          <c-box textAlign="right" h="10">
            <c-text fontSize="sm">Billed: ${{ bill.actualPrice }}</c-text>
            <c-text color="blue.400" fontSize="xs">Invoice</c-text>
          </c-box>
        </c-grid>
      </c-box>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getBillingHistory } from '../../../services/subscription';
export default {
  data() {
    return {
      isLoadingHistory: true,
      history: [],
    };
  },
  created() {
    this.getBillingHistory();
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
  },
  methods: {
    getBillingHistory() {
      getBillingHistory(this.activeCompany.id.toString())
        .then((res) => {
          this.isLoadingHistory = false;
          this.history = res.data.billing_history;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoadingHistory = false;
        });
    },
    formatDate(date) {
      return date && this.$moment(date).isValid()
        ? this.$moment(date).format('YYYY-MM-DD')
        : null;
    },
  },
};
</script>
