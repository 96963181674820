import { render, staticRenderFns } from "./BillingHistory.vue?vue&type=template&id=17f1c101"
import script from "./BillingHistory.vue?vue&type=script&lang=js"
export * from "./BillingHistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CSpinner: require('@chakra-ui/vue').CSpinner, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CGrid: require('@chakra-ui/vue').CGrid})
